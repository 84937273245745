<template>
  <v-container fluid>
    <v-row>
      <v-col md="5" class="custom-border-right">
        <table class="width-100">
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Project #</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              {{ projectArr.barcode }}
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Ref Job No.</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.soled_job_no">{{
                projectArr.soled_job_no
              }}</template>
              <template v-else
                ><em class="text--secondary">No Ref Job No.</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Client PO No.</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.client_po_no">{{
                projectArr.client_po_no
              }}</template>
              <template v-else
                ><em class="text--secondary">No Client PO No.</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Client PO Date</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.client_po_date">{{
                formatDate(projectArr.client_po_date)
              }}</template>
              <template v-else
                ><em class="text--secondary">No Client PO Date</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Reference #</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.reference">{{
                projectArr.reference
              }}</template>
              <template v-else
                ><em class="text--secondary">No Reference #</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Attention</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.attention">{{
                projectArr.attention
              }}</template>
              <template v-else
                ><em class="text--secondary">No Attention</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Customer</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.customer">
                <router-link
                  :to="
                    getDefaultRoute('customer.detail', {
                      params: {
                        id: projectArr.customer.customer,
                      },
                    })
                  "
                  >{{ projectArr.customer.company_name }}</router-link
                >
              </template>
              <template v-else
                ><em class="text--secondary">No Customer</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Billing Type</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.billing_type_text">{{
                projectArr.billing_type_text
              }}</template>
              <template v-else
                ><em class="text--secondary">No Billing Type</em></template
              >
            </td>
          </tr>
          <tr v-if="projectArr.billing_type == 1">
            <td class="pb-2 font-size-18 text-capitalize">Total Rate</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.cost">{{
                formatMoney(projectArr.cost)
              }}</template>
              <template v-else
                ><em class="text--secondary">No Rate</em></template
              >
            </td>
          </tr>
          <tr v-if="projectArr.billing_type == 2">
            <td class="pb-2 font-size-18 text-capitalize">Rate per hour</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.rate_per_hour">{{
                formatMoney(projectArr.rate_per_hour)
              }}</template>
              <template v-else
                ><em class="text--secondary">No Rate per hour</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Status</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.status_text">{{
                projectArr.status_text
              }}</template>
              <template v-else
                ><em class="text--secondary">No Status</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Date Created</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.added_at">{{
                formatDateTime(projectArr.added_at)
              }}</template>
              <template v-else
                ><em class="text--secondary">Not Created Yet</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Started At</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.started_at">{{
                formatDate(projectArr.started_at)
              }}</template>
              <template v-else
                ><em class="text--secondary">Not Started Yet</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Finished At</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.finished_at">{{
                formatDate(projectArr.finished_at)
              }}</template>
              <template v-else
                ><em class="text--secondary">Not Finished Yet</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">Deadline</td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.deadline">{{
                formatDate(projectArr.deadline)
              }}</template>
              <template v-else
                ><em class="text--secondary">No Deadline</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="pb-2 font-size-18 text-capitalize">
              Total Logged Hours
            </td>
            <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
              <template v-if="projectArr.total_logged_hours">{{
                projectArr.total_logged_hours
              }}</template>
              <template v-else
                ><em class="text--secondary">No Logged Hours</em></template
              >
            </td>
          </tr>
        </table>

        <div class="mt-4 custom-border-top">
          <h3 class="py-2 font-size-18 text-capitalize">description</h3>
          <template v-if="projectArr.description">
            <read-more
              class="custom-read-more font-size-18 pb-1"
              more-str="read more"
              :text="projectArr.description"
              link="#"
              less-str="read less"
              :max-chars="200"
            >
            </read-more>
          </template>
          <template v-else
            ><em class="text--secondary"
              >No description for this project</em
            ></template
          >
        </div>
        <div class="mt-5 custom-border-top" v-if="projectArr.members">
          <v-layout class="py-2">
            <v-flex>
              <h3 class="font-size-18 text-capitalize">members</h3>
            </v-flex>
            <v-flex
              class="text-right"
              v-if="getPermission('project:update') && projectArr.status < 4"
            >
              <v-btn icon v-on:click="memberDialog = true">
                <v-icon color="cyan">mdi-cog</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-list-item
            v-for="(member, index) in projectArr.members"
            :key="index"
          >
            <v-list-item-avatar>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="$assetAPIURL(member.user.profile_logo)"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-size-18">{{
                member.user.full_name
              }}</v-list-item-title>
              <v-list-item-subtitle
                class="font-size-14 text-capitalize line-height-1-5"
                >total logged hours :
                {{ member.logged_hours }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                v-if="getPermission('project:delete') && projectArr.status < 4"
                v-on:click="deleteMember(member)"
              >
                <v-icon color="red lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-col>
      <v-col md="7">
        <v-row>
          <v-col md="6" class="custom-border-right">
            <v-layout class="py-2">
              <v-flex>
                <h3 class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ projectArr.open_tasks }} /
                  {{ projectArr.total_tasks }} open tasks
                </h3>
              </v-flex>
              <v-flex class="text-right">
                <v-icon large color="cyan">mdi-check-circle-outline</v-icon>
              </v-flex>
            </v-layout>
            <p class="my-2">{{ projectArr.task_percentage }}%</p>
            <v-progress-linear
              :value="projectArr.task_percentage"
            ></v-progress-linear>
          </v-col>
          <v-col md="6">
            <v-layout class="py-2">
              <v-flex>
                <h3 class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ projectArr.days_left }} / {{ projectArr.total_days }} days
                  left
                </h3>
              </v-flex>
              <v-flex class="text-right">
                <v-icon large color="cyan">mdi-calendar-check</v-icon>
              </v-flex>
            </v-layout>
            <p class="my-2">{{ projectArr.days_percentage }}%</p>
            <v-progress-linear
              :value="projectArr.days_percentage"
            ></v-progress-linear>
          </v-col>
          <v-col md="12" class="custom-border-top">
            <v-row>
              <v-col md="6" class="custom-border-right">
                <v-layout class="py-2">
                  <v-flex class="">
                    <h3 class="pb-2 font-size-18 text-capitalize">
                      Total Budget
                    </h3>
                    <p
                      v-if="projectArr.project_budget >= 0"
                      class="my-2 font-size-18 font-weight-600 text-capitalize"
                    >
                      {{ formatMoney(projectArr.project_budget) }}
                    </p>
                  </v-flex>
                </v-layout>
              </v-col>
              <v-col md="6">
                <v-flex class="">
                  <h3 class="pb-2 font-size-18 text-capitalize">
                    total expenses
                  </h3>
                  <p
                    v-if="projectArr.total_expenses >= 0"
                    class="my-2 font-size-18 font-weight-600 text-capitalize"
                  >
                    {{ formatMoney(projectArr.total_expenses) }}
                  </p>
                </v-flex>
              </v-col>
            </v-row>
            <v-layout>
              <template v-if="false">
                <v-flex class="custom-border-right px-2">
                  <h3 class="pb-2 font-size-18 text-capitalize">
                    billable expenses
                  </h3>
                  <p class="my-2 font-size-18 font-weight-600 text-capitalize">
                    {{ formatMoney(projectArr.total_billable_expenses) }}
                  </p>
                </v-flex>
                <v-flex class="custom-border-right px-2">
                  <h3 class="pb-2 font-size-18 text-capitalize">
                    billed expenses
                  </h3>
                  <p class="my-2 font-size-18 font-weight-600 text-capitalize">
                    {{ formatMoney(projectArr.total_billed_expenses) }}
                  </p>
                </v-flex>
                <v-flex class="px-2">
                  <h3 class="pb-2 font-size-18 text-capitalize">
                    unbilled expenses
                  </h3>
                  <p class="my-2 font-size-18 font-weight-600 text-capitalize">
                    {{ formatMoney(projectArr.total_unbilled_expenses) }}
                  </p>
                </v-flex>
              </template>
            </v-layout>
          </v-col>
          <v-col md="12" class="custom-border-top">
            <div
              class="blue lighten-5 mt-4 pa-2 rounded-lg"
              style="border: 1px solid #cdc6c6 !important"
            >
              <v-layout class="align-items-center px-3">
                <div class="text-h5 fw-500">Project Budget</div>
                <v-spacer></v-spacer>
              </v-layout>
              <ProjectChart
                v-if="seriesData && categoriesData"
                :series-data="seriesData"
                :categories-data="categoriesData"
              ></ProjectChart>
            </div>
          </v-col>

          <v-col v-if="false" md="12" class="custom-border-top">
            <div class="card card-custom gutter-b">
              <div class="card-header">
                <div class="card-title justify-space-between width-100">
                  <h3 class="font-size-18 font-weight-600 text-capitalize">
                    Logged Hours
                  </h3>
                  <div
                    class="text-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
                  >
                    <v-select
                      :items="durationList"
                      v-model="loggedHours"
                      hide-details
                      :disabled="loggedHourLoading"
                      item-color="cyan"
                      class="pt-0 mt-0"
                      v-on:change="getLoggedHours"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <template v-if="loggedHourLoading">
                  <div class="min-height-400px d-flex align-center">
                    <v-progress-linear
                      color="primary"
                      indeterminate
                      rounded
                      height="6"
                    ></v-progress-linear>
                  </div>
                </template>
                <template v-else>
                  <apexchart
                    height="468"
                    type="bar"
                    :options="loggedHourChartoptions"
                    :series="loggedHourSeries"
                  ></apexchart>
                </template>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="getPermission('project:update')">
      <Dialog :commonDialog.sync="memberDialog">
        <template v-slot:title>Members</template>
        <template v-slot:body>
          <v-container class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  :items="memberList"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model.trim="members"
                  label="Members"
                  solo
                  flat
                  item-color="cyan"
                  item-text="full_name"
                  item-value="id"
                  hide-details
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 3">
                      <span>{{ item.full_name }}</span>
                    </v-chip>
                    <span v-if="index === 3" class="grey--text font-size-12">
                      (+{{ members.length - 3 }} others)
                    </span>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Member Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-container class="py-0">
            <v-row>
              <v-col md="12" class="text-right">
                <v-btn
                  v-on:click="memberDialog = false"
                  :disabled="pageLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  v-on:click="updateMembers"
                  color="cyan"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </Dialog>
    </template>
    <template v-if="getPermission('project:delete')">
      <MemberDelete :deleteDialog="deleteMemberDialog">
        <template v-slot:title>Delete Confirmation</template>
        <template v-slot:text>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Once you delete
            <span class="font-weight-700">{{ deleteDetail.full_name }}</span
            >, you won't be able to retrieve this later. Are you sure you want
            to delete
            <span class="font-weight-700">{{ deleteDetail.full_name }}</span>
            ?
          </h3>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="deleteLoading"
            :loading="deleteLoading"
            class="mx-2 custom-bold-button white--text"
            color="red lighten-1"
            v-on:click="deleteMemberConfirm"
          >
            Yes, Delete it
          </v-btn>

          <v-btn
            :disabled="deleteLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="deleteMemberDialog = false"
          >
            No, Keep it
          </v-btn>
        </template>
      </MemberDelete>
    </template>
  </v-container>
</template>

<script>
import { GET, DELETE, PATCH } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import MemberDelete from "@/view/pages/partials/Delete.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ProjectChart from "@/view/pages/dashboard/ProjectChart.vue";

export default {
  name: "project-detail",
  title: "Project",
  mixins: [CommonMixin, ValidationMixin],
  props: {
    projectArr: {
      type: [Object],
      required: true,
      default: () => {
        return new Object();
      },
    },
    project: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  watch: {
    "projectArr.members"(members) {
      if (members && members.length > 0) {
        this.members = members.map((row) => {
          return row.user.id;
        });
      }
    },
    projectArr: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.seriesData = param.seriesData;
          this.categoriesData = param.budgetCategories;
        }
      },
    },
  },
  data() {
    return {
      seriesData: [],
      categoriesData: [],
      memberDialog: false,
      pageLoading: false,
      deleteDetail: new Object(),
      deleteMemberDialog: false,
      members: [],
      memberList: [],
      loggedHours: "this_week",
      loggedHourLoading: false,
      loggedHourSeries: [{ data: [] }],
      loggedHourChartoptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "Last Week", value: "last_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last Month", value: "last_month" },
        { text: "This Year", value: "this_year" },
        { text: "Last Year", value: "last_year" },
      ],
    };
  },
  components: {
    Dialog,
    MemberDelete,
    ProjectChart,
  },
  methods: {
    deleteMember(member) {
      this.deleteDetail = member;
      this.deleteMemberDialog = true;
    },
    deleteMemberConfirm() {
      const _this = this;
      _this.deleteLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "project/" + _this.project + "/members/" + _this.deleteDetail.id,
        })
        .then(() => {
          _this.$emit("reload:project", true);
          _this.deleteDetail = new Object();
          _this.deleteMemberDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.deleteLoading = false;
        });
    },
    updateMembers() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "project/" + _this.project + "/members",
          data: { members: _this.members },
        })
        .then(() => {
          _this.$emit("reload:project", true);
          _this.memberDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getMembers() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "project/members",
        })
        .then(({ data }) => {
          _this.memberList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getLoggedHours() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "project/" + _this.project + "/graph/" + _this.loggedHours,
        })
        .then(({ data }) => {
          _this.loggedHourChartoptions = {
            ..._this.loggedHourChartoptions,
            ...{
              xaxis: {
                categories: data.categories,
              },
            },
          };
          _this.loggedHourSeries = [
            { name: "Logged Hours", data: data.values },
          ];
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  mounted() {
    this.getMembers();
    this.getLoggedHours();
  },
};
</script>
