var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Dialog',{attrs:{"commonDialog":_vm.importDialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Attach Files")]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"documentImport",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.importRows()}},model:{value:(_vm.valid_import),callback:function ($$v) {_vm.valid_import=$$v},expression:"valid_import"}},[_c('v-container',{staticClass:"pt-0 custom-search-filter",attrs:{"fluid":""}},[(_vm.getPermission('attachment:create'))?_c('div',{staticClass:"mt-4"},[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Attachments")]),_c('div',{staticClass:"text-center"},[_c('FileTemplateSingle',{attrs:{"attachments":_vm.attachment,"allowUpload":""},on:{"update:attachments":function($event){_vm.attachment=$event},"file:updated":_vm.updateFiles}})],1)]):_vm._e(),_c('br'),_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Name")]),_c('v-text-field',{attrs:{"dense":"","filled":"","color":"cyan","label":"Name","solo":"","flat":"","rules":[
                _vm.validateRules.required(_vm.uploadFile.name, 'Name'),
                _vm.validateRules.minLength(_vm.uploadFile.name, 'Name', 1),
                _vm.validateRules.maxLength(_vm.uploadFile.name, 'Name', 100) ]},model:{value:(_vm.uploadFile.name),callback:function ($$v) {_vm.$set(_vm.uploadFile, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.name"}})],1),_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Project")]),_c('v-autocomplete',{attrs:{"dense":"","color":"cyan","filled":"","items":_vm.projectList,"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"label":"Project","solo":"","flat":"","item-color":"cyan","item-text":"name","item-value":"id","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-capitalize font-weight-500 font-size-16"},[_vm._v("#"+_vm._s(item.barcode)+" - "+_vm._s(item.name))])],1)]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Project Found.')}})],1)],1)]},proxy:true}]),model:{value:(_vm.uploadFile.project),callback:function ($$v) {_vm.$set(_vm.uploadFile, "project", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.project"}})],1),_c('br'),_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Document Tags")]),_c('v-autocomplete',{attrs:{"dense":"","color":"cyan","filled":"","items":_vm.tagList,"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"label":"Document Tags","solo":"","flat":"","item-color":"cyan","item-text":"text","item-value":"text","hide-details":"","multiple":"","append-outer-icon":"mdi-cog"},on:{"click:append-outer":function($event){_vm.manageTagsDialog = true}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text font-size-12"},[_vm._v(" (+"+_vm._s(_vm.uploadFile.tags.length - 1)+" others) ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Tag Found.')}})],1)],1)]},proxy:true}]),model:{value:(_vm.uploadFile.tags),callback:function ($$v) {_vm.$set(_vm.uploadFile, "tags", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.tags"}})],1),_c('br'),_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Start Date")]),_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"Start Date","hide-details":"","solo":"","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.formattedStartDate,"color":"cyan"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDatePicker),callback:function ($$v) {_vm.startDatePicker=$$v},expression:"startDatePicker"}},[_c('v-date-picker',{attrs:{"color":"cyan"},on:{"input":function($event){_vm.startDatePicker = false}},model:{value:(_vm.uploadFile.start_date),callback:function ($$v) {_vm.$set(_vm.uploadFile, "start_date", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.start_date"}})],1)],1)]),_c('br'),_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Expiry Date")]),_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"Expiry Date","hide-details":"","solo":"","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.formattedEndDate,"color":"cyan"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDatePicker),callback:function ($$v) {_vm.endDatePicker=$$v},expression:"endDatePicker"}},[_c('v-date-picker',{attrs:{"color":"cyan"},on:{"input":function($event){_vm.endDatePicker = false}},model:{value:(_vm.uploadFile.end_date),callback:function ($$v) {_vm.$set(_vm.uploadFile, "end_date", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.end_date"}})],1)],1)]),_c('div',[_c('v-checkbox',{staticClass:"mr-4",attrs:{"label":"Is Public?","value":1,"hide-details":"","color":"cyan","disabled":_vm.pageLoading},model:{value:(_vm.uploadFile.is_public),callback:function ($$v) {_vm.$set(_vm.uploadFile, "is_public", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.is_public"}}),_c('v-checkbox',{staticClass:"mr-4",attrs:{"label":"Set Reminder","value":1,"hide-details":"","color":"cyan","disabled":_vm.pageLoading},model:{value:(_vm.uploadFile.set_reminder),callback:function ($$v) {_vm.$set(_vm.uploadFile, "set_reminder", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.set_reminder"}})],1),_c('br'),(_vm.uploadFile.set_reminder)?[_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Reminder Type")]),_c('v-autocomplete',{attrs:{"dense":"","color":"cyan","filled":"","items":_vm.reminderTypes,"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"label":"Reminder Types","solo":"","flat":"","item-color":"cyan","item-text":"text","item-value":"text","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Reminder Type Found.')}})],1)],1)]},proxy:true}],null,false,1370266361),model:{value:(_vm.uploadFile.reminder_type),callback:function ($$v) {_vm.$set(_vm.uploadFile, "reminder_type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.reminder_type"}})],1),_c('br'),(_vm.uploadFile.reminder_type == 'One Time Reminder')?[_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v(" Date")]),_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"Date","hide-details":"","solo":"","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.formattedReminderDate,"color":"cyan"}},'v-text-field',attrs,false),on))]}}],null,false,4169029372),model:{value:(_vm.reminderDatePicker),callback:function ($$v) {_vm.reminderDatePicker=$$v},expression:"reminderDatePicker"}},[_c('v-date-picker',{attrs:{"color":"cyan"},on:{"input":function($event){_vm.reminderDatePicker = false}},model:{value:(_vm.uploadFile.reminder_date),callback:function ($$v) {_vm.$set(_vm.uploadFile, "reminder_date", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.reminder_date"}})],1)],1)])]:[_c('br'),_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v(" Start Date")]),_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"Start Date","hide-details":"","solo":"","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.formattedReminderStartDate,"color":"cyan"}},'v-text-field',attrs,false),on))]}}],null,false,3112609052),model:{value:(_vm.reminderStartDatePicker),callback:function ($$v) {_vm.reminderStartDatePicker=$$v},expression:"reminderStartDatePicker"}},[_c('v-date-picker',{attrs:{"color":"cyan"},on:{"input":function($event){_vm.reminderStartDatePicker = false}},model:{value:(_vm.uploadFile.reminder_start_date),callback:function ($$v) {_vm.$set(_vm.uploadFile, "reminder_start_date", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.reminder_start_date"}})],1)],1)]),_c('br'),_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v(" Frequency")]),_c('div',[_c('v-text-field',{attrs:{"dense":"","color":"cyan","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"label":"Frequency","rules":[
                      _vm.validateRules.minLength(
                        _vm.uploadFile.frequency,
                        'Estimated Hours',
                        1
                      ),
                      _vm.validateRules.maxLength(
                        _vm.uploadFile.frequency,
                        'Estimated Hours',
                        100
                      ) ],"solo":"","flat":"","type":"number","min":"0","hide-details":""},on:{"keypress":function($event){return _vm.limitDecimal($event, _vm.uploadFile.frequency)}},model:{value:(_vm.uploadFile.frequency),callback:function ($$v) {_vm.$set(_vm.uploadFile, "frequency", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.frequency"}})],1)]),_c('br'),_c('div',[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v(" End Date")]),_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"End Date","hide-details":"","solo":"","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.formattedReminderEndDate,"color":"cyan"}},'v-text-field',attrs,false),on))]}}],null,false,3834948796),model:{value:(_vm.reminderEndDatePicker),callback:function ($$v) {_vm.reminderEndDatePicker=$$v},expression:"reminderEndDatePicker"}},[_c('v-date-picker',{attrs:{"color":"cyan"},on:{"input":function($event){_vm.reminderEndDatePicker = false}},model:{value:(_vm.uploadFile.reminder_end_date),callback:function ($$v) {_vm.$set(_vm.uploadFile, "reminder_end_date", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"uploadFile.reminder_end_date"}})],1)],1)])]]:_vm._e()],2)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"color":"cyan white--text"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Upload ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.loading_imported},on:{"click":function($event){return _vm.$emit('close-dialog', true)}}},[_vm._v(" Close ")])]},proxy:true}])}),_c('ManageTags',{attrs:{"commonDialog":_vm.manageTagsDialog,"tag":_vm.tagList},on:{"update:commonDialog":function($event){_vm.manageTagsDialog=$event},"update:common-dialog":function($event){_vm.manageTagsDialog=$event},"update:tag":function($event){_vm.tagList=$event},"close-dialog":function($event){_vm.manageTagsDialog = false},"get-expense-category":function($event){return _vm.geTags()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }