<template>
  <v-sheet>
    <div class="message-content h-100">
      <div
        class="d-flex justify-content-end"
        v-if="
          relatedType != 'customer-remark-notes' &&
          relatedType != 'lead-remark-notes'
        "
      >
        <v-row>
          <v-col class="col-md-8"></v-col>
          <v-col class="col-md-4 d-flex">
            <v-text-field
              right
              ref="search"
              v-model.trim="DataSearch"
              autocomplete="off"
              hide-details
              placeholder="search."
              outlined
              @keydown.esc="onEsc"
              @keydown.enter="getNotesRemark"
              style="max-width: 350px; min-width: 250px"
            >
              <template #prepend-inner>
                <v-icon color="grey" class="ml-1 mr-2"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
            <v-btn
              depressed
              color="white"
              class="blue darken-4"
              tile
              text
              small
              style="height: 30px"
              v-if="false"
              v-on:click="getNotesRemark"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              depressed
              color="white"
              class="red darken-4 ml-2"
              tile
              text
              small
              v-if="false"
              style="height: 30px; min-width: 0px !important"
              v-on:click="resetSearch"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="inbox">
        <!-- <div class="chat-box">
					<div class="chat-box-inner">
						<div class="wave">👋</div>
					        	<div style="font-weight: 500; font-size: 20px;">Start conversation</div> 
					</div>
				</div> -->
        <span class="divider-by-day" v-if="defaultRemakr"
          ><span>{{ dataOver }}</span></span
        >
        <div class="mine" v-if="defaultRemakr">
          <div class="message">
            <div v-html="defaultRemakr"></div>
            <br />
            <div style="text-align: end; color: #917a7a; font-size: 11px">
              {{ currentDate }}
            </div>
          </div>
        </div>
        <span
          class="divider-by-day"
          v-if="defaultRemakr == null && singleRemark"
          ><span>{{ dataOver }}</span></span
        >
        <div class="mine" v-if="defaultRemakr == null && singleRemark">
          <div class="message">
            <div v-html="singleRemark"></div>
            <br />
            <div style="text-align: end; color: #917a7a; font-size: 11px">
              {{ currentDate }}
            </div>
          </div>
        </div>
        <v-container class="bg-surface-variant">
          <template v-for="(data, index) in notesMsg">
            <v-row
              :justify="data.self ? 'end' : 'start'"
              :key="index"
              no-gutters
            >
              <v-col
                cols="6"
                :class="'text-' + (data.self ? 'right' : 'left') + ' mb-2'"
              >
                <div v-if="false && data.added_by">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        width="30px"
                        min-width="30px"
                        height="30px"
                        class="pa-0 lighten-3 white--text mr-1"
                        v-bind="attrs"
                        v-on="on"
                        left
                      >
                        <img
                          v-if="data.added_by.profile_logo"
                          :src="data.added_by.profile_logo"
                          :alt="data.added_by.display_name"
                        />
                        <img
                          v-else
                          src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                        />
                      </v-avatar>
                    </template>
                    <v-chip
                      color="blue"
                      text-color="white"
                    >
                    <span>{{ data.added_by.display_name }}</span>
                    </v-chip>
                    
                  </v-tooltip>
                </div>
                <div class="message" v-if="data">
                  <span
                    :class="'message_box' + (data.self ? ' self ' : ' other ')"
                    ><template v-if="data && data.notes_remark"
                      >{{ data.notes_remark }}<br />
                    </template>

                    <span
                      style="text-align: right; display: block; font-size: 11px"
                      ><v-chip
                      color="blue"
                      text-color="white"
                    >{{ data.added_by.display_name }}</v-chip>
                    <br />{{
                        data.created_at
                      }}</span
                    >
                  </span>
                </div>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </div>
      <div class="textarea" style="position: relative">
        <v-textarea
          style="border: 1px solid"
          prepend-inner-icon="mdi-attachment"
          @click:prepend-inner="uploadDialog()"
          hide-details
          class="ma-0"
          v-model.trim="notesData.notes_remark"
          outlined
          rows="2"
          placeholder="Messages"
          v-on:keypress.enter="onSubmit()"
        >
        </v-textarea>
        <v-icon
          class="send-btn cursor-pointer"
          style="position: absolute; right: 12px; text-align: right; top: 5px"
          v-on:click="onSubmit()"
          v-if="notesData.notes_remark"
          >mdi-send</v-icon
        >
      </div>
    </div>
    <add-dialog
      :document-dialog="openUploadModel"
      v-on:close="openUploadModel = false"
      v-on:success="getNotesRemark()"
      :related-id="relatedId"
      :chat="0"
      :related-type="relatedType"
    ></add-dialog>
  </v-sheet>
</template>
<style>
.v-dialog__content.v-dialog__content--active {
  z-index: 100 !important;
}
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
  font-size: 38px;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* For demonstration purposes only: */
body {
  font-size: 3.5em;
}
h1 {
  font-size: 0.5em;
}
.message-content .inbox {
  height: calc(100vh - 480px);
  overflow: auto;
}
.v-overlay.v-overlay--active.theme--dark {
  z-index: 99 !important;
}
</style>
<script>
import { QUERY, POST } from "@/core/services/store/request.module";
import MomentJS from "moment-timezone";

import { RemarkEventBus } from "@/core/lib/remark.lib";
import { LeadEventBus } from "@/core/lib/leadremark.lib";

import { last, toString } from "lodash";

export default {
  name: "notes-and-remarks",
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    relatedId: {
      type: Number,
      default: 0,
    },
    relatedType: {
      type: String,
      default: null,
    },
    createUrl: {
      type: String,
      default: null,
    },
    defaultRemakr: {
      type: String,
      default: null,
    },
    defaultDate: {
      type: String,
      default: null,
    },
    getUrl: {
      type: String,
      default: null,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    relatedUrl: {
      type: String,
      default: null,
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getNotesRemark();
        }
      },
    },
    relatedId: {
      handler(param) {
        if (param) {
          this.getNotesRemark();
          this.dataOver = MomentJS().format("dddd, MMMM D, YYYY, h:mm A");
        }
      },
    },
    /* isDialog(param){
			if(param){
                this.getSingleRemark()
			}
		} */
  },
  data() {
    return {
      notesData: {
        notes_remark: null,
      },
      DataSearch: null,
      notesMsg: [],
      selected_date: "all",
      dataOver: null,
      singleRemark: null,
      parent: null,
      dataLists: [
        { value: "all", text: "All" },
        { value: "lead-notes", text: "Lead" },
        { value: "customer-notes", text: "Customer" },
        { value: "task-notes", text: "Task" },
        { value: "meeting-notes", text: "Meeting" },
      ],
      dataListsCustomer: [
        { value: "all", text: "All" },
        { value: "customer-notes", text: "Customer" },
        { value: "meeting-notes", text: "Meeting" },
      ],
      dataTask: [
        { value: "all", text: "All" },
        { value: "task-notes", text: "Task" },
        { value: "meeting-notes", text: "Meeting" },
      ],
      pageLoading: false,
      currentDate: null,
      openUploadModel: false,
    };
  },

  components: {},
  methods: {
    getFileExtension(path) {
      if (path) {
        return last(path.split("."));
      }
    },
    FileDownload(data) {
      window.open(data, "_blank");
    },

    onEsc() {
      this.resetSearch();
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.DataSearch = null;
        this.getNotesRemark();
      });
    },
    uploadDialog() {
      this.openUploadModel = true;
    },
    onSubmit() {
      this.pageLoading = true;
      this.$store
        .dispatch(POST, {
          url: `project-remarks/${this.parent}`,
          data: this.notesData,
        })
        .then(() => {
          this.notesData.notes_remark = "";
          if (this.defaultRemakr == null && this.relatedUrl) {
            this.getSingleRemark();
          }
          if (this.relatedType == "customer-notes") {
            RemarkEventBus.$emit("table-refresh", true);
          }
          if (this.relatedType == "lead-notes") {
            LeadEventBus.$emit("table-lead-refresh", true);
          }

          this.getNotesRemark();
          this.$emit("refress", true);

          this.$refs.form.reset();
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    getNotesRemark(filter) {
      this.$store
        .dispatch(QUERY, {
          url: `project-remarks/${this.parent}`,
          data: {
            search: this.DataSearch,
            filter: filter,
          },
        })
        .then(({ data }) => {
          this.notesMsg = data;
        })
        .catch((error) => {
          console.log({
            error,
          });
        });
    },
    getSingleRemark() {
      this.$store
        .dispatch(QUERY, {
          url: `${this.relatedUrl}/${this.relatedId}`,
        })
        .then((data) => {
          if (!this.defaultRemakr) {
            if (this.relatedUrl == "lead") {
              this.defaultRemakr = data.lead.remark;
              this.singleRemark = data.lead.remark;
            } else {
              this.defaultRemakr = data.customer.remark;
              this.singleRemark = data.customer.remark;
            }
          }
        })
        .catch((error) => {
          console.log({
            error,
          });
        });
    },
  },
  computed: {
    btxPlaceholder() {
      if (this.relatedType == "lead-notes") {
        return this.dataLists;
      } else if (this.relatedType == "customer-notes") {
        return this.dataListsCustomer;
      } else if (this.relatedType == "task-notes") {
        return this.dataTask;
      }
      return "";
    },
  },
  mounted() {
    //this.getNotesRemark();
    this.currentDate = MomentJS().format("HH:II A");
    this.dataOver = toString(this.defaultDate);
    this.dataOver = MomentJS().format("dddd, MMMM D, YYYY, h:mm A");
  },
};
</script>
