<template>
  <div>
    <Dialog :commonDialog="importDialog">
      <template v-slot:title>Attach Files</template>
      <template v-slot:body>
        <!-- <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 60vh; position: relative"
        > -->
        <v-form
          ref="documentImport"
          v-model="valid_import"
          lazy-validation
          v-on:submit.stop.prevent="importRows()"
        >
          <v-container fluid class="pt-0 custom-search-filter">
            <div class="mt-4" v-if="getPermission('attachment:create')">
              <label class="font-weight-700 font-size-16">Attachments</label>
              <div class="text-center">
                <FileTemplateSingle
                  :attachments.sync="attachment"
                  v-on:file:updated="updateFiles"
                  allowUpload
                ></FileTemplateSingle>
              </div>
            </div>
            <br />

            <div>
              <label class="font-weight-700 font-size-16">Name</label>
              <v-text-field
                v-model.trim="uploadFile.name"
                dense
                filled
                color="cyan"
                label="Name"
                solo
                flat
                :rules="[
                  validateRules.required(uploadFile.name, 'Name'),
                  validateRules.minLength(uploadFile.name, 'Name', 1),
                  validateRules.maxLength(uploadFile.name, 'Name', 100),
                ]"
              ></v-text-field>
            </div>
            <div>
              <label class="font-weight-700 font-size-16">Project</label>
              <v-autocomplete
                dense
                color="cyan"
                filled
                :items="projectList"
                :disabled="pageLoading"
                :loading="pageLoading"
                v-model.trim="uploadFile.project"
                label="Project"
                solo
                flat
                item-color="cyan"
                item-text="name"
                item-value="id"
                hide-details
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize font-weight-500 font-size-16"
                      >#{{ item.barcode }} - {{ item.name }}</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="'No Project Found.'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </div>
            <br />
            <div>
              <label class="font-weight-700 font-size-16">Document Tags</label>
              <v-autocomplete
                dense
                color="cyan"
                filled
                :items="tagList"
                :disabled="pageLoading"
                :loading="pageLoading"
                v-model.trim="uploadFile.tags"
                label="Document Tags"
                solo
                flat
                item-color="cyan"
                item-text="text"
                item-value="text"
                hide-details
                multiple
                append-outer-icon="mdi-cog"
                v-on:click:append-outer="manageTagsDialog = true"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text font-size-12">
                    (+{{ uploadFile.tags.length - 1 }} others)
                  </span>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="'No Tag Found.'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </div>
            <br />
            <div>
              <label class="font-weight-700 font-size-16">Start Date</label>
              <div>
                <v-menu
                  v-model="startDatePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      flat
                      label="Start Date"
                      hide-details
                      solo
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="formattedStartDate"
                      color="cyan"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model.trim="uploadFile.start_date"
                    color="cyan"
                    v-on:input="startDatePicker = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <br />
            <div>
              <label class="font-weight-700 font-size-16">Expiry Date</label>
              <div>
                <v-menu
                  v-model="endDatePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      flat
                      label="Expiry Date"
                      hide-details
                      solo
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="formattedEndDate"
                      color="cyan"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model.trim="uploadFile.end_date"
                    color="cyan"
                    v-on:input="endDatePicker = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div>
              <v-checkbox
                v-model.trim="uploadFile.is_public"
                label="Is Public?"
                :value="1"
                hide-details
                color="cyan"
                class="mr-4"
                :disabled="pageLoading"
              ></v-checkbox>
              <v-checkbox
                v-model.trim="uploadFile.set_reminder"
                label="Set Reminder"
                :value="1"
                hide-details
                color="cyan"
                class="mr-4"
                :disabled="pageLoading"
              ></v-checkbox>
            </div>
            <br />
            <template v-if="uploadFile.set_reminder">
              <div>
                <label class="font-weight-700 font-size-16"
                  >Reminder Type</label
                >
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  :items="reminderTypes"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model.trim="uploadFile.reminder_type"
                  label="Reminder Types"
                  solo
                  flat
                  item-color="cyan"
                  item-text="text"
                  item-value="text"
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    {{ item.text }}
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Reminder Type Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>
              <br />
              <template v-if="uploadFile.reminder_type == 'One Time Reminder'">
                <div>
                  <label class="font-weight-700 font-size-16"> Date</label>
                  <div>
                    <v-menu
                      v-model="reminderDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Date"
                          hide-details
                          solo
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedReminderDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model.trim="uploadFile.reminder_date"
                        color="cyan"
                        v-on:input="reminderDatePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
              </template>
              <template v-else>
                <br />
                <div>
                  <label class="font-weight-700 font-size-16">
                    Start Date</label
                  >
                  <div>
                    <v-menu
                      v-model="reminderStartDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Start Date"
                          hide-details
                          solo
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedReminderStartDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model.trim="uploadFile.reminder_start_date"
                        color="cyan"
                        v-on:input="reminderStartDatePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <br />
                <div>
                  <label class="font-weight-700 font-size-16"> Frequency</label>
                  <div>
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="uploadFile.frequency"
                      label="Frequency"
                      :rules="[
                        validateRules.minLength(
                          uploadFile.frequency,
                          'Estimated Hours',
                          1
                        ),
                        validateRules.maxLength(
                          uploadFile.frequency,
                          'Estimated Hours',
                          100
                        ),
                      ]"
                      solo
                      flat
                      type="number"
                      min="0"
                      hide-details
                      v-on:keypress="limitDecimal($event, uploadFile.frequency)"
                    ></v-text-field>
                  </div>
                </div>
                <br />
                <div>
                  <label class="font-weight-700 font-size-16"> End Date</label>
                  <div>
                    <v-menu
                      v-model="reminderEndDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="End Date"
                          hide-details
                          solo
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedReminderEndDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model.trim="uploadFile.reminder_end_date"
                        color="cyan"
                        v-on:input="reminderEndDatePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
              </template>
            </template>
          </v-container>
        </v-form>
        <!-- </perfect-scrollbar> -->
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="onSubmit()"
        >
          Upload
        </v-btn>
        <v-btn
          :disabled="loading_imported"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="$emit('close-dialog', true)"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <ManageTags
      :commonDialog.sync="manageTagsDialog"
      :tag.sync="tagList"
      v-on:close-dialog="manageTagsDialog = false"
      v-on:get-expense-category="geTags()"
    ></ManageTags>
  </div>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";

import { POST, QUERY } from "@/core/services/store/request.module";

import Dialog from "@/view/pages/partials/Dialog.vue";

import JwtService from "@/core/services/jwt.service";
import FileTemplateSingle from "@/view/pages/partials/FileTemplateSingle.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";

import moment from "moment-timezone";
import AppConfiguration from "@/core/config/app.config";
import ManageTags from "@/view/pages/partials/Manage-Tags.vue";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";

export default {
  mixins: [CommonMixin, ListingMixin, ValidationMixin, FileManagerMixin],
  name: "project-list",
  props: {
    importDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    projectId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    projectId: {
      immediate: true,
      handler(param) {
        this.uploadFile.project = param;
      },
    },
  },
  data() {
    return {
      pageLoading: true,
      attachment: new Array(),

      manageTagsDialog: false,
      status: "all",
      file: "",

      excel_imported: false,
      valid_import: true,
      loading_imported: false,
      error_message: [],
      success_message: [],
      statusList: [],
      tagList: new Array(),
      projectList: new Array(),
      reminderTypes: [
        { text: "One Time Reminder" },
        { text: "Daily Reminder" },
        { text: "Weekly Reminder" },
        { text: "Monthly Reminder" },
        { text: "Quarterly Reminder" },
        { text: "Half Yearly Reminder" },
      ],
      startDatePicker: false,
      endDatePicker: false,
      reminderDatePicker: false,
      reminderStartDatePicker: false,
      reminderEndDatePicker: false,
      uploadFile: {
        file: [
          {
            id: null,
            name: null,
          },
        ],
        name: null,
        project: this.projectId,
        tags: [],
        start_date: null,
        end_date: null,
        set_reminder: 0,
        is_public: 0,
        reminder_date: null,
        reminder_type: "One Time Reminder",
        reminder_start_date: null,
        reminder_end_date: null,
        frequency: null,
      },
    };
  },
  components: {
    Dialog,
    FileTemplateSingle,
    ManageTags,
  },
  methods: {
    isExpired(param) {
      if (moment(param.expiry_date).isBefore(moment())) {
        return true;
      }
      return false;
    },
    updateFiles(param) {
      this.uploadFile.file = param;
      this.uploadFile.name = param[0].name;
    },
    downloadSample() {
      const token = JwtService.getToken();
      const url = this.$apiURL + "project/sample-download?token=" + token;
      window.open(url, "_target");
    },

    handleFileUpload(param) {
      this.file = param;
      this.excel_imported = false;
      this.success_message = [];
      this.error_message = [];
    },

    onSubmit() {
      const _this = this;
      try {
        _this.formLoading = true;

        _this.$store
          .dispatch(POST, {
            url: "document/upload",
            data: _this.uploadFile,
          })
          .then(({ data }) => {
            //_this.importDialog = false;

            _this.$emit("close-dialog", true);
            _this.$emit("get-document-list", true);

            //_this.getRows();
            _this.uploadFile = {
              file: [
                {
                  id: null,
                  name: null,
                },
              ],
              name: null,
              project: null,
              tags: [],
              start_date: null,
              end_date: null,
              set_reminder: 0,
              is_public: 0,
              reminder_date: null,
              reminder_type: "One Time Reminder",
              reminder_start_date: null,
              reminder_end_date: null,
              frequency: null,
            };
            console.log(data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },

    geTags() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "project/tags",
          data: { customer: _this.customer },
        })
        .then(({ data }) => {
          _this.tagList = data;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getProjects() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "document/projects",
        })
        .then(({ data }) => {
          _this.projectList = data;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  computed: {
    formattedReminderStartDate() {
      let Config = AppConfiguration.get();
      if (Config && this.uploadFile.reminder_start_date) {
        return moment(this.uploadFile.reminder_start_date).format(
          Config.dateFormat
        );
      }
      return this.uploadFile.reminder_start_date;
    },
    formattedReminderEndDate() {
      let Config = AppConfiguration.get();
      if (Config && this.uploadFile.reminder_end_date) {
        return moment(this.uploadFile.reminder_end_date).format(
          Config.dateFormat
        );
      }
      return this.uploadFile.reminder_end_date;
    },
    formattedReminderDate() {
      let Config = AppConfiguration.get();
      if (Config && this.uploadFile.reminder_date) {
        return moment(this.uploadFile.reminder_date).format(Config.dateFormat);
      }
      return this.uploadFile.reminder_date;
    },
    formattedStartDate() {
      if (
        this.uploadFile.end_date &&
        moment(this.uploadFile.start_date).isAfter(this.uploadFile.end_date)
      ) {
        return null;
      }
      let Config = AppConfiguration.get();
      if (Config && this.uploadFile.start_date) {
        return moment(this.uploadFile.start_date).format(Config.dateFormat);
      }
      return this.uploadFile.start_date;
    },
    formattedEndDate() {
      if (
        this.uploadFile.start_date &&
        moment(this.uploadFile.end_date).isBefore(this.uploadFile.start_date)
      ) {
        return null;
      }
      let Config = AppConfiguration.get();
      if (Config && this.uploadFile.end_date) {
        return moment(this.uploadFile.end_date).format(Config.dateFormat);
      }
      return this.uploadFile.end_date;
    },
  },
  mounted() {
    this.geTags();
    this.getProjects();
  },
};
</script>
