<template>
  <!--begin::Milestone Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2">
          <v-select
            :disabled="dataLoading"
            :loading="dataLoading"
            :items="statusList"
            v-model="filter.status"
            hide-details
            flat
            solo
            dense
            label="Status"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
            v-on:change="getAllMilestones"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip :color="item.color" :text-color="item.textcolor">
                  <template v-if="item.value == 'all'">{{
                    item.all_milestones
                  }}</template>
                  <template v-else>{{ item.milestone_status_count }}</template>
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllMilestones"
            @keydown.tab="getAllMilestones"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Dates Range"
                hide-details
                solo
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:change="getAllMilestones"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllMilestones"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <template v-if="detail.status < 4">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:click="createDialog()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create New Milestone</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="7"
      :dataLoading.sync="dataLoading"
      :rowData.sync="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th text-center" width="30" align="center">
              Milestone #
            </th>
            <th class="simple-table-th" width="300">Title / Description</th>
            <th class="simple-table-th text-center" width="50" align="center">
              %
            </th>
            <th class="simple-table-th" width="200">Dates</th>
            <th class="simple-table-th" width="200">Payments</th>
            <th class="simple-table-th" width="200">Tags</th>
            <th class="simple-table-th" width="100">Status</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length">
            <tr v-for="(row, index) in dataRows" :key="index" v-on:click="showDetail(row)">
              <td width="30" class="simple-table-td font-size-16" align="center">
                <Barcode :barcode="row.barcode"/>
              </td>
              <td width="300" class="simple-table-td">
                <p class="my-0 mb-1 font-size-16"><span class="font-weight-600">Title: </span>{{row.title}}</p>
                <p class="my-0 mb-1 font-size-16 custom-nowrap-ellipsis-two-line"><span class="font-weight-600">Description: </span>{{row.description}}</p>
              </td>
              <td width="50" class="simple-table-td" align="center">
                <v-progress-circular
                  :rotate="-90"
                  :size="60"
                  :width="10"
                  :value="row.percentage"
                  color="green darken-1"
                >
                  {{ row.percentage }}%
                </v-progress-circular>
              </td>
              <td width="200" class="simple-table-td">              
                <p class="font-size-16 my-0 mb-1"><span class="font-weight-600">Start Date:</span> {{formatDate(row.start_date)}}</p>
                <p class="font-size-16 my-0 mb-1"><span class="font-weight-600">End Date:</span> {{formatDate(row.end_date)}}</p>
                <span v-if="row.past_date" class="font-size-16 red--text text--darken-1 font-weight-600">({{row.date_string}})</span>
                <span v-else class="font-size-16 green--text text--darken-1 font-weight-600">({{row.date_string}})</span>
              </td>
              <td width="200" class="simple-table-td">              
                <p class="font-size-16 my-0 mb-1"><span class="font-weight-600">Total: </span> {{formatMoney(row.amount)}}</p>
                <p class="font-size-16 my-0 mb-1 deep-orange--text text--darken-1"><span class="font-weight-600">Invoiced: </span> {{formatMoney(row.invoiced)}}</p>
                <p class="font-size-16 my-0 mb-1 green--text text--darken-1"><span class="font-weight-600">Paid: </span> {{formatMoney(row.paid)}}</p>
              </td>
              <td width="200" class="simple-table-td" align="center">
                <template v-for="(grow, gindex) in row.tags">
                  <v-chip
                    v-if="gindex == 3"
                    :key="`more-${gindex}`"
                    small
                    class="font-size-16 my-1 mx-2 font-weight-600"
                  >
                    +{{ row.tags.length - 3 }} More...
                  </v-chip>
                  <v-chip
                    v-if="gindex < 3"
                    :key="`chip-${gindex}`"
                    small
                    class="font-size-16 my-1 mx-2 font-weight-500"
                    :color="getRandomColor(gindex)"
                  >
                    {{ grow }}
                  </v-chip>
                </template>
              </td>
              <td width="100" class="simple-table-td">
                <v-chip
                  label
                  class="font-size-16 text-uppercase my-1 mx-2 font-weight-600"
                  :color="`${row.status_relation.color} ${row.status_relation.textcolor}--text`"
                >
                  {{ row.status_relation.text }}
                </v-chip>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="7">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no milestone at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <Dialog :common-dialog="detail_dialog" :dialog-width="dialogWidth" dense>
      <template v-slot:title>
        <v-layout>
          <v-flex class="d-flex align-center">
            {{milestone_detail.title}}
            <Barcode class="mx-2" v-if="milestone_detail.barcode" :barcode="milestone_detail.barcode"/>
            <v-chip   
              v-if="milestone_detail.status_relation"      
              label
              class="text-uppercase my-1 mx-2 font-weight-600"
              :text-color="milestone_detail.status_relation.textcolor"
              :color="milestone_detail.status_relation.color"
            >
              {{milestone_detail.status_relation.text}}
            </v-chip>            
          </v-flex>
          <v-flex class="text-right">            
            <v-menu
              transition="slide-y-transition"
              bottom
              content-class="custom-menu-list"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="milestone_loading"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  v-bind="attrs"
                  v-on="on"
                >
                  More... <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="milestone_detail.status != 1" link v-on:click="update_status('draft')">
                  <v-list-item-title class="font-weight-500 font-size-14">Mark as Draft</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="milestone_detail.status != 2" link v-on:click="update_status('in-progress')">
                  <v-list-item-title class="font-weight-500 font-size-14">Mark as In-Progress</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="milestone_detail.status != 3" link v-on:click="update_status('completed')">
                  <v-list-item-title class="font-weight-500 font-size-14">Mark as Completed</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn :disabled="milestone_loading" v-on:click="edit_milestone()" class="custom-bold-button mx-2" depressed color="cyan white--text">Edit</v-btn>
            <v-btn :disabled="milestone_loading" v-on:click="detail_dialog = false" class="custom-bold-button" depressed>Close</v-btn>
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:body>
        <v-container fluid>
          <div class="d-flex">
            <v-chip
              label
              outlined
              class="text-uppercase mb-2 mx-2 font-weight-600"
            >
              Total: {{formatMoney(milestone_detail.amount)}}
            </v-chip>
            <v-chip         
              label
              outlined
              class="text-uppercase mb-2 mx-2 font-weight-600"
              color="deep-orange--text text--darken-1"
            >
              Invoiced: {{formatMoney(milestone_detail.invoiced)}}
            </v-chip>
            <v-chip         
              label
              outlined
              class="text-uppercase mb-2 mx-2 font-weight-600"
              color="green--text text--darken-1"
            >
              Paid: {{formatMoney(milestone_detail.paid)}}
            </v-chip>
          </div>
          <v-tabs
            v-model="milestoneTab"
            background-color="transparent"
            color="cyan"
            class="custom-tab-transparent"
          >
            <v-tab class="font-size-16 font-weight-600 px-8" href="#overview">
              Overview
            </v-tab>
            <v-tab
              class="font-size-16 font-weight-600 px-8"
              href="#deliverable"
            >
              Deliverables
            </v-tab>
            <v-tab class="font-size-16 font-weight-600 px-8" href="#invoice">
              Invoices
            </v-tab>
            <v-tab class="font-size-16 font-weight-600 px-8" href="#payment">
              Payments
            </v-tab>
            <v-tabs-items v-model="milestoneTab">
              <v-tab-item value="overview">
                <v-container>
                  <table width="100%" style="table-layout: fixed">
                    <tbody>
                      <tr>
                        <td class="font-size-16" width="200px" height="30px" valign="middle"><span class="font-weight-600">Milestone #</span></td>
                        <td class="font-size-16" height="30px" valign="middle">{{milestone_detail.barcode}}</td>
                        <td class="font-size-16" width="200px" height="30px" valign="middle"><span class="font-weight-600">Start Date</span></td>
                        <td class="font-size-16" height="30px" valign="middle">{{formatDate(milestone_detail.start_date)}}</td>
                      </tr>
                      <tr>
                        <td class="font-size-16" width="200px" height="30px" valign="middle"><span class="font-weight-600">Title</span></td>
                        <td class="font-size-16" height="30px" valign="middle">{{milestone_detail.title}}</td>
                        <td class="font-size-16" width="200px" height="30px" valign="middle"><span class="font-weight-600">End Date</span></td>
                        <td class="font-size-16" height="30px" valign="middle">{{formatDate(milestone_detail.end_date)}}</td>
                      </tr>
                      <tr>
                        <td class="font-size-16" width="200px" height="30px" valign="middle"><span class="font-weight-600">Project Budget</span></td>
                        <td class="font-size-16" height="30px" valign="middle">{{formatMoney(milestone_detail.budget)}}</td>
                        <td class="font-size-16" width="200px" height="30px" valign="middle"><span class="font-weight-600">Quoted Amount</span></td>
                        <td class="font-size-16" height="30px" valign="middle">{{formatMoney(milestone_detail.quoted_sum)}}</td>
                      </tr>
                      <tr>
                        <td class="font-size-16" width="200px" height="30px" valign="middle"><span class="font-weight-600">Payment Type</span></td>
                        <td class="font-size-16" height="30px" valign="middle">
                          <span v-if="milestone_detail.type == 'amount'">Amount ($)</span>
                          <span v-if="milestone_detail.type == 'percentage'">Percent (%)</span>
                        </td>
                        <td class="font-size-16" width="200px" height="30px" valign="middle">
                          <span class="font-weight-600" v-if="milestone_detail.type == 'amount'">Payment Value (in $)</span>
                          <span class="font-weight-600" v-if="milestone_detail.type == 'percentage'">Payment Value (in %)</span>
                        </td>
                        <td class="font-size-16" height="30px" valign="middle">{{formatMoney(milestone_detail.value)}}</td>
                      </tr>
                      <tr>
                        <td class="font-size-16" width="200px" height="30px" valign="middle"><span class="font-weight-600">Description</span></td>
                        <td class="font-size-16" height="30px" valign="middle" colspan="3">{{milestone_detail.description}}</td>
                      </tr>
                      <tr v-if="false">
                        <td class="font-size-16" width="200px" height="30px" valign="middle"><span class="font-weight-600">Quotations</span></td>
                        <td class="font-size-16" height="30px" valign="middle" colspan="3">
                          <v-chip v-for="(row, index) in del_quotation_lists" :key="index" small class="my-1 mx-2 font-size-16 font-weight-600">
                            <span class="font-weight-600">Quotation #: </span> {{row.barcode}}
                            <v-icon small class="mx-2 my-0 py-0">mdi-record</v-icon>
                            <span class="font-weight-600">Title: </span> {{row.job_title}}
                          </v-chip>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-16" width="200px" height="30px" valign="middle"><span class="font-weight-600">Tags</span></td>
                        <td class="font-size-16" height="30px" valign="middle" colspan="3">
                          <template v-if="milestone_detail && milestone_detail.tags && milestone_detail.tags.length">
                          <v-chip v-for="(row, index) in milestone_detail.tags" :key="index" :color="getRandomColor(index)" small class="my-1 mx-2 font-weight-600">
                            {{row}}
                          </v-chip>
                          </template>
                          <template v-else>
                            <em class="text--secondary">No Tags</em>
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-container>
              </v-tab-item>
              <v-tab-item value="deliverable">
                <v-container>
                  <v-layout class="mb-3">
                    <v-flex md6>
                      <v-select
                        hide-details
                        flat
                        solo
                        dense
                        :items="del_quotation_lists"
                        placeholder="Quotation"
                        color="cyan"
                        item-value="id"
                        item-text="barcode"
                        item-color="cyan"
                        class="pt-0 mt-0"
                        clearable
                        v-model="ft_quotation"
                        v-on:change="filter_quotation($event)"
                      >                      
                        <template v-slot:item="{ attrs, item }">
                          <div class="d-flex align-center font-size-16">
                            <p class="my-0 font-size-16"><span class="font-weight-600">Quotation #</span> {{item.barcode}}</p> <v-icon small class="mx-2 my-0 py-0">mdi-record</v-icon>
                            <p class="my-0 font-size-16 text-ellipsis" style="max-width: 300px"><span class="font-weight-600">Title</span> {{item.job_title}}</p>
                          </div>
                        </template>
                        <template v-slot:selection="{ attrs, item, select, selected, index }">
                          <div class="d-flex align-center font-size-16">
                            <p class="my-0 font-size-16"><span class="font-weight-600">Quotation #</span> {{item.barcode}}</p> <v-icon small class="mx-2 my-0 py-0">mdi-record</v-icon>
                            <p class="my-0 font-size-16 text-ellipsis" style="max-width: 300px"><span class="font-weight-600">Title</span> {{item.job_title}}</p>
                          </div>
                        </template>
                      </v-select>
                    </v-flex>
                    <v-flex md6 class="text-right">
                      <v-btn :disabled="!totalDelivery || inv_loading" :loading="inv_loading" v-on:click="generate_invoice()" color="cyan white--text" class="custom-bold-button">GENERATE INVOICE</v-btn>
                    </v-flex>
                  </v-layout>
                  <div class="custom-listing-table">
                    <v-simple-table
                      class="simple-table custom-table-height"
                      fixed-header
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="simple-table-th text-center"
                              width="30"
                              align="center"
                            >
                              #
                            </th>
                            <th class="simple-table-th" width="300">
                              Product/Service/Equipment
                            </th>
                            <th class="simple-table-th" width="200">Quote</th>
                            <th class="simple-table-th text-center" width="100" align="center">Qty quoted</th>
                            <th class="simple-table-th text-center" width="100" align="center">Qty Delivered</th>
                            <th class="simple-table-th text-center" width="100" align="center">Qty to be delivered</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="deliverables.length">
                          <tr v-for="(row, index) in deliverables" :key="index" :class="{'row-delivered': row.delivered}">
                            <td class="simple-table-td">
                              <template v-if="row.quantity > row.qty_delivered">
                                <v-checkbox :disabled="inv_loading" class="mx-0 my-0 py-0 px-0" v-model="row.status" :true-value="true" :false-value="false" color="cyan" hide-details></v-checkbox>
                              </template>
                            </td>
                            <td class="simple-table-td">
                              <Barcode class="mx-2 font-size-16" :barcode="row.product_relation.barcode"/>
                              <p class="my-0 font-size-16"><span class="font-weight-600">Name: </span> {{row.product_relation.name}}</p>
                              <p class="my-0 font-size-16 custom-nowrap-ellipsis-two-line"><span class="font-weight-600">Description: </span> {{row.product_relation.description}}</p>
                            </td>
                            <td class="simple-table-td">
                              <Barcode class="mx-2 font-size-16" :barcode="row.quotation_relation.barcode"/>
                              <p class="my-0 font-size-16"><span class="font-weight-600">Title: </span> {{row.quotation_relation.job_title}}</p>
                              <p class="my-0 font-size-16 custom-nowrap-ellipsis-two-line"><span class="font-weight-600">Description: </span> {{clean_html(row.quotation_relation.description)}}</p>
                            </td>
                            <td class="simple-table-td" align="center">
                              <p class="my-0 font-weight-600 font-size-16 text-capitalize">{{row.quantity}} {{row.product_relation.unit.text}}</p>
                            </td>
                            <td class="simple-table-td" align="center">
                              <p class="my-0 font-weight-600 font-size-16 text-capitalize">{{row.qty_delivered}} {{row.product_relation.unit.text}}</p>
                            </td>
                            <td class="simple-table-td">
                              <template v-if="row.quantity > row.qty_delivered">
                              <v-text-field
                                clearable
                                dense
                                filled
                                flat
                                v-model="row.del_quantity"
                                label="Quantity"
                                hide-details
                                solo
                                :disabled="inv_loading"
                                :loading="inv_loading"
                                color="cyan"
                                type="number"
                                :suffix="row.product_relation.unit.text"
                              ></v-text-field>
                            </template>
                            <template v-else>
                              <v-btn small color="green darken-4 white--text" class="w-100 custom-bold-button" depressed>Delivered</v-btn>
                            </template>
                            </td>
                          </tr>
                          </template>
                          <template v-else>
                            <tr>
                            <td colspan="6">
                              <p
                                class="m-0 row-not-found text-center font-weight-500 font-size-16"
                              >
                                <img
                                  :src="$assetURL('media/error/empty.png')"
                                  class="row-not-found-image"
                                />
                                Uhh... There are no deliverables at the moment.
                              </p>
                            </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-container>
              </v-tab-item>
              <v-tab-item value="invoice">
                <v-container>
                  <div class="custom-listing-table">
                    <v-simple-table
                      class="simple-table custom-table-height"
                      fixed-header
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="simple-table-th text-center"
                              width="20"
                              align="center"
                            >
                              #
                            </th>
                            <th class="simple-table-th" width="200">Invoice</th>
                            <th class="simple-table-th text-center" width="100" align="center">Payment</th>
                            <th class="simple-table-th text-center" width="100" align="center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="invoices.length">
                          <tr v-for="(row, index) in invoices" :key="index" >
                            <td class="simple-table-td" width="20">
                              {{ index + 1 }}
                            </td>
                            <td class="simple-table-td">
                              <Barcode class="mx-2 font-size-16" :barcode="row.barcode"/>
                              <p class="my-0 font-size-16"><span class="font-weight-600">Subject: </span> {{row.title}}</p>
                              <p class="my-0 font-size-16"><span class="font-weight-600">Reference: </span> {{row.reference}}</p>
                            </td>
                            <td class="simple-table-td" align="center">
                              <p class="my-0 font-size-16 font-weight-600 deep-orange--text text--darken-1">Total: {{formatMoney(row.total)}}</p>
                              <p class="my-0 font-size-16 font-weight-600 green--text text--darken-1">Paid: {{formatMoney(row.paid_amount)}}</p>
                            </td>
                            <td class="simple-table-td" align="center">
                              <v-chip
                                v-if="row.paid_amount == 0" 
                                label
                                small
                                class="text-uppercase my-1 mx-2 font-weight-600 font-size-16"
                                color="red lighten-1 white--text"
                              >
                                Un-Paid
                              </v-chip>
                              <v-chip
                                v-else-if="row.paid_amount >= row.total" 
                                label
                                small
                                class="text-uppercase my-1 mx-2 font-weight-600 font-size-16"
                                color="green darken-1 white--text"
                              >
                                Paid
                              </v-chip>
                              <v-chip
                                v-else-if="row.paid_amount > 0" 
                                label
                                small
                                class="text-uppercase my-1 mx-2 font-weight-600 font-size-16"
                                color="orange darken-1 white--text"
                              >
                                Partial Paid
                              </v-chip>
                            </td>
                          </tr>
                          </template>
                          <template v-else>
                            <tr>
                            <td colspan="4">
                              <p
                                class="m-0 row-not-found text-center font-weight-500 font-size-16"
                              >
                                <img
                                  :src="$assetURL('media/error/empty.png')"
                                  class="row-not-found-image"
                                />
                                Uhh... There are no invoices at the moment.
                              </p>
                            </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-container>
              </v-tab-item>
              <v-tab-item value="payment">
                <v-container>
                  <div class="custom-listing-table">
                    <v-simple-table
                      class="simple-table custom-table-height"
                      fixed-header
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="simple-table-th text-center"
                              width="20"
                              align="center"
                            >
                              #
                            </th>
                            <th class="simple-table-th" width="200">Payment</th>
                            <th class="simple-table-th" width="200">Invoice</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="payments.length">
                            <tr v-for="(row, index) in payments" :key="index" >
                              <td class="simple-table-td" width="20">
                                {{ index + 1 }}
                              </td>
                              <td class="simple-table-td">
                                <Barcode class="mx-2 font-size-16" :barcode="row.barcode"/>
                                <p class="my-0 font-size-16"><span class="font-weight-600">Date: </span> {{ formatDate(row.payment_date) }}</p>
                                <p class="my-0 font-size-16">
                                  <span class="font-weight-600">Amount: </span>
                                  <span class="green--text text--darken-1">{{ formatMoney(row.amount) }}</span>
                                  <em class="text--secondary" v-if="row.payment_mode">({{ row.payment_mode.text }})</em>
                                </p>
                              </td>
                              <td class="simple-table-td">
                                <template v-if="row.invoice">
                                  <Barcode class="mx-2 font-size-16" :barcode="row.invoice.barcode"/>
                                  <p class="my-0 font-size-16"><span class="font-weight-600">Subject: </span> {{ row.invoice.title }}</p>
                                  <p class="my-0 font-size-16"><span class="font-weight-600">Reference: </span> {{ row.invoice.reference }}</p>
                                </template>
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr>
                            <td colspan="4">
                              <p
                                class="m-0 row-not-found text-center font-weight-500 font-size-16"
                              >
                                <img
                                  :src="$assetURL('media/error/empty.png')"
                                  class="row-not-found-image"
                                />
                                Uhh... There are no payment at the moment.
                              </p>
                            </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-container>
      </template>
    </Dialog>
    <Dialog :common-dialog="create_dialog" :dialog-width="dialogWidth">
      <template v-slot:title>
        <span v-if="milestone.id">Update Milestone - {{milestone.title}}</span>
        <span v-else>Create Milestone</span>        
      </template>
      <template v-slot:body>
        <v-container fluid>
          <v-form>
            <table width="100%" style="table-layout: fixed">
              <tr>
                <td width="200px" valign="middle">
                  <label for="milestone-title">Title</label>
                </td>
                <td class="py-1" colspan="3">
                  <v-text-field
                    id="milestone-title"
                    dense
                    filled
                    flat
                    v-model="milestone.title"
                    label="Title"
                    hide-details
                    solo
                    color="cyan"
                    :disabled="milestone_loading"
                    :loading="milestone_loading"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td width="200px" valign="top">
                  <label for="milestone-description" class="mt-2"
                    >Description</label
                  >
                </td>
                <td class="py-1" colspan="3">
                  <v-textarea
                    id="milestone-description"
                    auto-grow
                    dense
                    filled
                    v-model="milestone.description"
                    color="cyan"
                    label="Description"
                    hide-details
                    solo
                    flat
                    row-height="25"
                    :disabled="milestone_loading"
                    :loading="milestone_loading"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <td width="200px" valign="middle">
                  <label for="milestone-start-date">Start Date</label>
                </td>
                <td class="py-1">
                  <Datepicker :default-date="milestone.start_date" :disabled="milestone_loading" :loading="milestone_loading" v-model="milestone.start_date" solo id="milestone-start-date"></Datepicker>
                </td>
                <td width="200px" valign="middle" class="px-4">
                  <label for="milestone-end-date">End Date</label>
                </td>
                <td class="py-1">
                  <Datepicker :default-date="milestone.end_date" :disabled="milestone_loading" :loading="milestone_loading" v-model="milestone.end_date" solo id="milestone-end-date"></Datepicker>
                </td>
              </tr>
              <tr>
                <td width="200px" valign="middle">
                  <label for="milestone-quotations">Quotations</label>
                </td>
                <td class="py-1" colspan="3">
                  <v-autocomplete
                    id="milestone-quotations"
                    v-model="milestone.quotations"
                    :items="quotationList"
                    chips
                    clearable
                    label="Quotations"
                    hide-details
                    color="cyan"
                    item-color="cyan"
                    item-value="id"
                    dense
                    multiple
                    filled
                    solo
                    flat
                    v-on:change="update_sum()"
                    :disabled="milestone_loading"
                    :loading="milestone_loading"
                  >
                    <template v-slot:item="{ attrs, item }">
                      <div class="d-flex align-center font-size-16">
                        <p class="my-0 font-size-16">
                          <span class="font-weight-600">Quotation #</span>
                          {{ item.barcode }}
                        </p>
                        <v-icon small class="mx-2 my-0 py-0">mdi-record</v-icon>
                        <p class="my-0 font-size-16">
                          <span class="font-weight-600">Title</span>
                          {{ item.job_title }}
                        </p>
                        <v-icon small class="mx-2 my-0 py-0">mdi-record</v-icon>
                        <p class="my-0 font-size-16">
                          <span class="font-weight-600">Amount</span>
                          {{ formatMoney(item.total) }}
                        </p>
                      </div>
                    </template>
                    <template
                      v-slot:selection="{
                        attrs,
                        item,
                        select,
                        selected,
                        index,
                      }"
                    >
                      <div
                        class="qt-select-chip d-flex align-center font-size-16"
                      >
                        <p class="my-0 font-size-16">
                          <span class="font-weight-600">Quotation #</span>
                          {{ item.barcode }}
                        </p>
                        <v-icon small class="mx-2 my-0 py-0">mdi-record</v-icon>
                        <p class="my-0 font-size-16">
                          <span class="font-weight-600">Title</span>
                          {{ item.job_title }}
                        </p>
                        <v-icon small class="mx-2 my-0 py-0">mdi-record</v-icon>
                        <p class="my-0 font-size-16">
                          <span class="font-weight-600">Amount</span>
                          {{ formatMoney(item.total) }}
                        </p>
                      </div>
                    </template>
                  </v-autocomplete>
                </td>
              </tr>
              <tr>
                <td width="200px" valign="middle">
                  <label for="milestone-project-budget">
                    Project Budget
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span>Total budget of project.</span>
                    </v-tooltip>
                  </label>
                </td>
                <td class="py-1">
                  <v-text-field
                    id="milestone-project-budget"
                    dense
                    filled
                    flat
                    :value="detail.project_budget"
                    label="Project Budget"
                    hide-details
                    solo
                    type="number"
                    color="cyan"
                    readonly
                    :disabled="milestone_loading"
                    :loading="milestone_loading"
                  ></v-text-field>
                </td>
                <td width="200px" valign="middle" class="px-4">
                  <label for="milestone-quoted-amount">
                    Quoted Amount
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        >Auto calculated based on Quotation selections.</span
                      >
                    </v-tooltip>
                  </label>
                </td>
                <td class="py-1">
                  <v-text-field
                    id="milestone-quoted-amount"
                    dense
                    filled
                    flat
                    v-model="milestone.quoted_sum"
                    label="Quoted Amount"
                    hide-details
                    solo
                    type="number"
                    color="cyan"
                    :disabled="milestone_loading"
                    :loading="milestone_loading"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td width="200px" valign="middle">
                  <label for="milestone-payment-type">Payment Type</label>
                </td>
                <td class="py-1">
                  <v-radio-group
                    :disabled="milestone_loading"
                    v-model="milestone.type"
                    row
                    color="cyan"
                    hide-details
                    class="mt-0 pt-0"
                  >
                    <v-radio
                      color="cyan"
                      label="Amount ($)"
                      value="amount"
                    ></v-radio>
                    <v-radio
                      color="cyan"
                      label="Percent (%)"
                      value="percentage"
                    ></v-radio>
                  </v-radio-group>
                </td>
                <td width="200px" valign="middle" class="px-4">
                  <label for="milestone-payment-value">
                    Payment Value
                    <span
                      class="font-weight-600"
                      v-if="milestone.type == 'amount'"
                      >(in $)</span
                    >
                    <span class="font-weight-600" v-else>(in %)</span>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-1" v-bind="attrs" v-on="on"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span>Milestone Amount</span>
                    </v-tooltip>
                  </label>
                </td>
                <td class="py-1">
                  <v-text-field
                    id="milestone-payment-value"
                    dense
                    filled
                    flat
                    v-model="milestone.value"
                    label="Payment Value"
                    hide-details
                    solo
                    type="number"
                    color="cyan"
                    :disabled="milestone_loading"
                    :loading="milestone_loading"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td width="200px" valign="middle">
                  <label for="milestone-tags">Tags</label>
                </td>
                <td class="py-1" colspan="3">
                  <v-combobox
                    id="milestone-tags"
                    v-model="milestone.tags"
                    :items="tags"
                    chips
                    clearable
                    label="Tags"
                    multiple
                    hide-details
                    color="cyan"
                    item-color="cyan"
                    dense
                    filled
                    solo
                    flat
                    v-on:click:clear="update_list()"
                    v-on:change="update_list()"
                    :disabled="milestone_loading"
                    :loading="milestone_loading"
                  >
                    <template v-slot:item="{ attrs, item }">
                      <label
                        class="my-0 mx-0 text-uppercase font-weight-600 py-1 px-0 font-size-16"
                        >{{ item }}</label
                      >
                    </template>
                    <template
                      v-slot:selection="{
                        attrs,
                        item,
                        select,
                        selected,
                        index,
                      }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        class="font-weight-400 text-uppercase"
                        :color="getRandomColor(index)"
                        @click="select"
                        @click:close="remove(item)"
                      >
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </td>
              </tr>
            </table>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="milestone_loading"
          v-on:click="create_dialog = false"
          class="custom-bold-button"
          >Cancel</v-btn
        >
        <v-btn
          :disabled="milestone_loading"
          :loading="milestone_loading"
          v-on:click="save_milestone()"
          class="custom-bold-button"
          color="cyan white--text"
          >Save</v-btn
        >
      </template>
    </Dialog>
  </v-container>
  <!--end::Milestone Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY, POST, PUT, GET } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ObjectPath from "object-path";
import { v4 as uuidv4 } from 'uuid';

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "milestone-internal-list",
  data() {
    return {
      parent: 0,
      milestoneTab: null,
      milestone_loading: false,
      detail_dialog: false,
      create_dialog: false,
      dates: [],
      del_checkbox: [],
      statusList: [],
      dataRows: [],
      dataLoading: true,
      ft_quotation: null,
      customerList: [],
      quotationList: [],
      invoices: [],
      payments: [],
      del_quotation_lists: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
      tags: [],
      dbTags: [],
      deliverables: [],
      milestone_detail: {},
      inv_loading: false,
      milestone: {
        id: null,
        project: 0,
        customer: 0,
        reference: null,
        title: null,
        description: null,
        start_date: null,
        end_date: null,
        tags: [],
        quotations: [],
        type: "amount",
        quoted_sum: null,
        value: null,
      },
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getAllMilestones();
        }
      },
    },
  },
  components: {
    Dialog,
    Barcode,
    Datepicker,
    ListingTable,
  },
  methods: {
    update_status(status) {
      this.milestone_loading = true;
      this.$store.dispatch(PUT, { url: `milestone/${this.milestone_detail.id}/status`, data: { status } })
        .then(() => {
          this.milestone_loading = true;
          this.$store.dispatch(GET, { url: `milestone/${this.milestone_detail.id}` })
            .then(({data}) => {
              this.milestone_detail = data;
              this.deliverables = data.deliverables;
              this.invoices = data.invoices;
              this.payments = data.payments;
              this.del_quotation_lists = this.lodash.map(data.milestone_quotations, 'quotation_relation');
            })
            .catch((error) => {
              this.logError(error);
            })
            .finally(() => {
              this.milestone_loading = false;
            });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.milestone_loading = false;
        });
    },
    edit_milestone() {
      this.detail_dialog = false;
      this.milestone = {
        id: this.milestone_detail.id,
        project: this.milestone_detail.project,
        customer: this.milestone_detail.customer,
        reference: this.milestone_detail.reference,
        title: this.milestone_detail.title,
        description: this.milestone_detail.description,
        start_date: this.milestone_detail.start_date,
        end_date: this.milestone_detail.end_date,
        tags: this.milestone_detail.tags,
        quotations: this.lodash.map(this.milestone_detail.milestone_quotations, 'quotation'),
        type: this.milestone_detail.type,
        quoted_sum: this.milestone_detail.quoted_sum,
        value: this.milestone_detail.value,
      }
      this.$nextTick(() => {
        this.create_dialog = true;
      });
    },
    generate_invoice() {
      let deliverables = this.lodash.filter(this.deliverables, { status: true });
      if(!deliverables.length){
        return false
      }
      this.inv_loading = true;
      let uuid = uuidv4();
      this.$store.dispatch(POST, {
        url: "milestone/temp-generate-invoice",
        data: {
          uuid,
          deliverables,
          project: this.detail.id,
          milestone: this.milestone_detail.id
        }
      })
      .then(({data}) => {
        this.$router.push(
          this.getDefaultRoute("invoice.create", {
            query: {
              customer: data.customer,
              contact_person: data.contact_person,
              property: data.property,
              project: this.detail.id,
              milestone: data.milestone,
              uuid,
            },
          })
        );
      })
      .catch((error) => {
        this.logError(error);
      })
      .finally(() => {
        this.inv_loading = false;
      });
    },
    filter_quotation(id) {
      if(!id){
        this.deliverables = this.milestone_detail.deliverables;
        return false;
      }
      this.deliverables = this.lodash.filter(this.milestone_detail.deliverables, {quotation: id});
    },
    clean_html(string) {
      if(!string){
        return "";
      }
      return string.replace(/<\/?[^>]+(>|$)/g, "");
    },
    update_sum() {
      let totalSum = [];
      for (let i = this.milestone.quotations.length - 1; i >= 0; i--) {
        let qtObject = this.lodash.find(this.quotationList, {
          id: this.milestone.quotations[i],
        });
        if (qtObject) {
          totalSum.push(qtObject.total);
        }
      }
      this.milestone.quoted_sum = this.lodash.sum(totalSum);
    },
    get_options() {
      this.$store
        .dispatch(QUERY, {
          url: "milestone/options",
          data: { project: this.milestone.project },
        })
        .then(({ data }) => {
          this.milestone.customer = data.project.customer;
          this.milestone.project = data.project.id;
          this.quotationList = this.lodash.cloneDeep(data.quotations);
          this.tags = this.lodash.cloneDeep(data.tags);
          this.dbTags = this.lodash.cloneDeep(data.tags);
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    update_list() {
      this.tags = this.lodash.cloneDeep(this.dbTags);
      for (let i = 0; i < this.milestone.tags.length; i++) {
        if (this.tags.indexOf(this.milestone.tags[i]) < 0) {
          this.tags.push(this.milestone.tags[i]);
        }
      }
    },
    remove(item) {
      this.milestone.tags.splice(this.milestone.tags.indexOf(item), 1);
    },
    createDialog() {
      this.milestone.id = null;
      this.milestone.reference = null;
      this.milestone.title = null;
      this.milestone.description = null;
      this.milestone.start_date = null;
      this.milestone.end_date = null;
      this.milestone.tags = [];
      this.milestone.quotations = [];
      this.milestone.type = 'amount';
      this.milestone.quoted_sum = null;
      this.milestone.value = null;
      this.$nextTick(() => {
        this.create_dialog = true;
      });
    },
    save_milestone() {
      this.milestone_loading = true;
      this.milestone.budget = this.detail.project_budget;

      let requestType = POST;
      let requestURL = "milestone";

      if(this.milestone.id){
        requestType = PUT;
        requestURL = `milestone/${this.milestone.id}`;
      }

      if(!this.milestone.start_date){
        this.milestone.start_date = moment().format('YYYY-MM-DD')
      }

      if(!this.milestone.end_date){
        this.milestone.end_date = moment().format('YYYY-MM-DD')
      }

      this.$store.dispatch(requestType, { url: requestURL, data: this.milestone })
        .then(() => {
          this.create_dialog = false;
          this.getAllMilestones();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.milestone_loading = false;
        });
    },
    showDetail(row) {
      this.milestone_loading = true;
      this.milestone_detail = {};
      this.deliverables = [];
      this.invoices = [];
      this.payments = [];
      this.del_quotation_lists = [];
      this.detail_dialog = false;
      this.milestoneTab = "overview";
      this.ft_quotation = null;
      this.$store.dispatch(GET, { url: `milestone/${row.id}` })
        .then(({data}) => {
          this.milestone_detail = data;
          this.deliverables = data.deliverables;
          this.invoices = data.invoices;
          this.payments = data.payments;
          this.del_quotation_lists = this.lodash.map(data.milestone_quotations, 'quotation_relation');
          this.detail_dialog = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.milestone_loading = false;
        });
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllMilestones();
    },
    getAllMilestones() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "milestone",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 90);
    },
    totalDelivery() {
      return this.lodash.filter(this.deliverables, { status: true }).length;
    }
  },
  mounted() {
    this.get_options();
  },
  created() {
    this.milestone.customer = ObjectPath.get(this.detail, "customer.id", null);
    this.milestone.project = ObjectPath.get(this.detail, "id", null);
    if (!this.milestone.project) {
      this.milestone.project = ObjectPath.get(this.$route, "params.id");
    }
  },
};
</script>
