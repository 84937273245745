<template>
  <div>
    <div class="project_doc_count">
      <PageHeaderCount
        moduleType="budget"
        :dataLoading.sync="dataLoading"
        :statusList.sync="statusList"
        allkey="all_documents"
        countkey="documents_status_count"
      ></PageHeaderCount>
    </div>
    <v-layout class="min-height-57px" v-if="false">
      <v-flex
        class="font-level-3-bold my-auto d-flex justify-space-between w-100"
      >
        <div class="detail-svg-icon d-flex align-center">
          <!--begin::Svg Icon-->
          <inline-svg
            fill="#006fc8"
            :src="$assetURL('media/custom-svg/budget.svg')"
            height="20"
            width="20"
            class="mr-2"
          />
          <!--end::Svg Icon-->
          <template> Budget </template>
        </div>
      </v-flex>
    </v-layout>
    <template v-if="false">
      <v-container class="grey lighten-5">
        <v-row>
          <v-col cols="3" md="3">
            <v-card outlined tile style="background: #00bcd4; color: #fff">
              <span
                style="
                  font-size: 20px;
                  float: left;

                  padding: 11px;
                  display: block;
                "
                ><b>Total Budget</b></span
              >
              <span
                style="
                  font-size: 20px;
                  float: right;

                  padding: 11px;
                  display: block;
                "
                ><b>2000</b></span
              >
              <div style="clear: both"></div>
            </v-card>
          </v-col>
          <v-col cols="3" md="3">
            <v-card class="pa-2" outlined tile> Total Expenses </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <div
      v-if="!pageLoading"
      class="overflow-y"
      style="max-height: calc(100vh - 337px); min-height: 200px"
    >
      <v-form
        v-model.trim="formValid"
        ref="budgetForm"
        lazy-validation
        class="budget-listing"
      >
        <v-simple-table
          class="bt-table listing-table table-head-sticky budget1"
        >
          <thead>
            <tr>
              <th width="40px">#</th>
              <th>Category</th>
              <th>Name</th>
              <th>Cost</th>
              <th width="100px">Action</th>
            </tr>
          </thead>
          <tbody v-if="loadedBudget.length">
            <template v-for="(row, index) in loadedBudget">
              <tr :key="index + 1" class="cursor-pointer">
                <td style="vertical-align: top" class="py-3">
                  {{ index + 1 }}
                </td>
                <td style="vertical-align: top" class="py-3">
                  <v-autocomplete
                    dense
                    filled
                    color="cyan"
                    item-color="cyan"
                    :items="expenseCategoryList"
                    label="Category"
                    solo
                    flat
                    hide-details
                    item-text="text"
                    item-value="value"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    append-outer-icon="mdi-cog"
                    v-on:click:append-outer="manageExpenseCategoryDialog = true"
                    v-model.trim="row.category"
                    :rules="[validateRules.required(row.category, 'Category')]"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Category Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </td>
                <td class="pb-3">
                  <v-text-field
                    v-model.trim="row.name"
                    :rules="[validateRules.required(row.name, 'Budget Name')]"
                    dense
                    filled
                    label="Name"
                    solo
                    flat
                    color="cyan"
                    class="required-field"
                  ></v-text-field>

                  <table width="100%" class="sub-budget">
                    <tr
                      v-for="(subBudget, index) in row.sub_budgets"
                      :key="index + 4"
                      class="cursor-pointer ml-auto"
                      style="min-width: 100%"
                    >
                      <td>
                        <v-text-field
                          v-model.trim="subBudget.name"
                          :rules="[
                            validateRules.required(
                              subBudget.name,
                              'Sub Budget Name'
                            ),
                          ]"
                          dense
                          filled
                          label="Name"
                          solo
                          flat
                          color="cyan"
                          class="required-field"
                        ></v-text-field>
                      </td>
                    </tr>
                  </table>
                </td>
                <td class="pb-3">
                  <v-text-field
                    dense
                    filled
                    v-model.trim="row.cost"
                    label="Cost"
                    :rules="[
                      validateRules.required(row.cost, 'Cost'),
                      validateRules.minLength(row.cost, 'Cost', 1),
                      validateRules.maxLength(row.cost, 'Cost', 100),
                    ]"
                    solo
                    flat
                    type="number"
                    min="0"
                    class="required-field"
                    v-on:keypress="limitDecimal($event, row.cost)"
                  ></v-text-field>

                  <table width="100%" class="sub-budget">
                    <tr
                      v-for="(subBudget, subBudgetIndex) in row.sub_budgets"
                      :key="subBudgetIndex + 4"
                      class="cursor-pointer"
                      style="min-width: 100%"
                    >
                      <td class="d-flex align-center">
                        <v-text-field
                          dense
                          filled
                          v-model.trim="subBudget.cost"
                          label="Cost"
                          :rules="[
                            validateRules.required(subBudget.cost, 'Cost'),
                            validateRules.minLength(subBudget.cost, 'Cost', 1),
                            validateRules.maxLength(
                              subBudget.cost,
                              'Cost',
                              100
                            ),
                          ]"
                          solo
                          flat
                          type="number"
                          min="0"
                          class="required-field"
                          v-on:keypress="limitDecimal($event, subBudget.cost)"
                        ></v-text-field>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                              style="margin-bottom: 30px"
                            >
                              <v-btn
                                @click="
                                  deleteSubBudget(
                                    index,
                                    subBudgetIndex,
                                    subBudget
                                  )
                                "
                                class="red white--text ml-4"
                                fab
                                x-small
                              >
                                <v-icon color="white" x-small
                                  >mdi-delete</v-icon
                                >
                              </v-btn>
                            </span>
                          </template>
                          <span>Delete Sub Budget</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </table>
                </td>

                <td width="200px" class="py-3" style="vertical-align: top">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn
                          @click.prevent.stop="createSubBudget(index, false)"
                          color="blue darken-4 white--text"
                          dark
                          fab
                          x-small
                        >
                          <v-icon>mdi-plus</v-icon></v-btn
                        >
                      </span>
                    </template>
                    <span>Create Sub Budget</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn
                          @click="deleteBudget(index, row)"
                          class="red white--text ml-4"
                          fab
                          x-small
                        >
                          <v-icon color="white">mdi-delete</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>Delete Budget</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot v-else>
            <tr>
              <td colspan="11">
                <p class="m-0 row-not-found text-center py-3">
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no budget at the moment.
                </p>
              </td>
            </tr>
          </tfoot>
        </v-simple-table>
      </v-form>

      <ManageExpenseCategory
        :commonDialog.sync="manageExpenseCategoryDialog"
        :expenseCategory.sync="expenseCategoryList"
        v-on:close-dialog="manageExpenseCategoryDialog = false"
        v-on:get-expense-category="getExpenseCategory()"
      ></ManageExpenseCategory>

      <div class="d-flex mt-4">
        <v-btn
          @click.stop.prevent="addBudgetRow"
          color="blue darken-4 white--text"
          tile
        >
          <v-icon>mdi-plus</v-icon> Add Budget</v-btn
        >
        <v-btn
          v-if="loadedBudget.length > 0"
          :loading="pageLoading"
          @click.stop.prevent="onSubmit()"
          color="blue darken-4 white--text"
          class="ml-4"
          tile
        >
          <v-icon>mdi-upload</v-icon>Save Budgets</v-btn
        >
      </div>
    </div>
    <div v-else class="mx-20 my-40 text-center">
      <v-progress-linear
        color="orange darken-2"
        indeterminate
        height="6"
      ></v-progress-linear>
    </div>
    <Dialog :dialog="deleteDialog" :dialog-width="640">
      <template v-slot:title> Delete Budget</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/attention-circle.svg')"
              />
              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0">
              <span class="text-capitalize">Budget</span>
              <span class="red--text font-bolder lighten-1">
                # {{ uuid }}
              </span>
              will be deleted forever and cannot be retrieved later. Are you
              sure about deleting it?
            </p>
          </v-col>
          <!-- <v-col v-if="deleteNote" md="12" class="pb-0 mt-2 my-auto">
						<em>Note: {{ deleteNote }}</em>
					</v-col> -->
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn class="white--text" depressed color="red lighten-1" tile>
          Yes! Delete
        </v-btn>
        <v-btn depressed tile @click="deleteDialog = false"> No, Close </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.v-data-table__wrapper table tbody td,
.v-data-table__wrapper table tbody td table tr td {
  padding-bottom: 0 !important;
}
</style>

<script>
import moment from "moment-timezone";
import { GET, POST, CLEAR_ERROR } from "@/core/services/store/request.module";

import CommonMixin from "@/core/plugins/common-mixin";

import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import ManageExpenseCategory from "@/view/pages/partials/Manage-Expense-Category.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "task-internal-list",
  data() {
    return {
      statusList: [
        {
          text: "Total Budget",
          value: "Total Budget",
          color: "cyan",
          textcolor: "white",
          headercolor: "primary",
          top_visible: true,
          documents_status_count: 1,
        },
        {
          text: "Total Expenses",
          value: "Total Expenses",
          color: "black",
          textcolor: "white",
          headercolor: "primary",
          top_visible: true,
          documents_status_count: 0,
        },
      ],
      budgetSNO: null,
      uuid: null,
      subBudgetUUID: null,
      budgetUUID: null,
      isApproveDialogOpen: false,
      isMilestoneDetailDialogOpen: false,
      isCreateMilestoneDialog: false,
      formValid: true,
      budgets: [],
      deleteDialog: false,
      deleteSubBudgetDialog: false,
      deleteBudgetDialog: false,
      budget: {
        category: null,
        name: null,
        cost: null,
      },
      loadedBudget: [],

      pageLoading: false,
      expenseCategoryList: new Array(),
      dataLoading: true,
      manageExpenseCategoryDialog: false,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getBudget();
        }
      },
    },
  },
  components: {
    ManageExpenseCategory,
    PageHeaderCount,
  },
  methods: {
    addBudgetRow() {
      const budget = {
        category: null,
        name: null,
        cost: null,
        editable: true,
        sub_budgets: [],
      };
      this.loadedBudget = [...this.loadedBudget, budget];
    },
    createSubBudget(index) {
      const sub_budgets = {
        name: null,
        cost: null,
        editable: true,
      };
      if (this.loadedBudget[index].sub_budgets) {
        this.loadedBudget[index].sub_budgets = [
          ...this.loadedBudget[index].sub_budgets,
          sub_budgets,
        ];
      } else {
        this.loadedBudget[index].sub_budgets.push({
          name: null,
          cost: null,
        });
      }
    },
    getExpenseCategory() {
      this.pageLoading = true;
      this.$store
        .dispatch(GET, {
          url: "expense/category",
        })
        .then(({ data }) => {
          this.expenseCategoryList = data;
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    getBudget() {
      this.pageLoading = true;
      if (!this.detail.id) {
        return false;
      }
      this.$store
        .dispatch(GET, {
          url: `project/budget/${this.detail.id}`,
        })
        .then(({ data }) => {
          this.loadedBudget = data.rows;
          this.statusList = data.status;
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.pageLoading = false;
          this.dataLoading = false;
        });
    },
    onSubmit() {
      const _this = this;
      try {
        const validateStatus = _this.$refs.budgetForm.validate();

        const formErrors = _this.validateForm(_this.$refs.budgetForm);
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        if (!validateStatus) {
          return false;
        }

        _this.$store.dispatch(CLEAR_ERROR, {});

        _this.formLoading = true;
        _this.$store
          .dispatch(POST, {
            url: "project/budget",
            data: {
              project: this.detail.id,
              budgets: this.loadedBudget,
            },
          })
          .then(({ data }) => {
            _this.getBudget();
            _this.$router.push(
              _this.getDefaultRoute("project.detail", {
                params: { id: data.id },
              })
            );
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
  },
  mounted() {
    this.getExpenseCategory();
    this.getBudget();
  },
};
</script>
