<template>
  <!--begin::Ticket Internal Listing-->
  <v-container fluid>
    <v-container>
      <div class="project_doc_count">
        <PageHeaderCount
          moduleType="document"
          :dataLoading.sync="dataLoading"
          :statusList.sync="statusList"
          allkey="all_documents"
          countkey="documents_status_count"
        ></PageHeaderCount>
      </div>
      <v-row>
        <v-col md="2">
          <v-select
            :disabled="dataLoading"
            :loading="dataLoading"
            :items="statusList"
            v-model="filter.status"
            hide-details
            flat
            solo
            dense
            label="Status"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
            v-on:change="getAllDocumentss"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip :color="item.color" :text-color="item.textcolor">
                  <template v-if="item.value == 'all'">{{
                    item.all_documents
                  }}</template>
                  <template v-else>{{ item.documents_status_count }}</template>
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllDocumentss"
            @keydown.tab="getAllDocumentss"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Dates Range"
                hide-details
                solo
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:change="getAllDocumentss"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="3">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllDocumentss"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <template>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:click="importDialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create Document</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="5"
      :dataLoading.sync="dataLoading"
      :rowData.sync="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Name</th>
            <th class="simple-table-th" width="200">Document Tags</th>
            <th class="simple-table-th" width="200">Dates</th>
            <th class="simple-table-th" width="200">Reminder</th>
            <th class="simple-table-th" width="200">Modified</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr link :key="index" v-for="(data, index) in dataRows">
              <td class="simple-table-td">
                <v-btn
                  class="mx-2 custom-bold-button"
                  color="cyan"
                  outlined
                  small
                  :loading="isDownloading(index)"
                  :disabled="primaryLoader || isDownloading(index)"
                  icon
                  v-on:click="downloadAPIFile(data.id, data.file.name, index)"
                  ><v-icon>mdi-download</v-icon>
                </v-btn>
                {{ data.name }}
                <div v-if="isExpired(data)" class="project-listing-status">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        class="custom-status d-inline font-weight-600 custom-grey-border text-uppercase justify-center"
                        color="cyan"
                        text-color="white"
                        label
                        small
                      >
                        Expired
                      </v-chip>
                    </template>
                    <span>Status</span>
                  </v-tooltip>
                </div>
              </td>
              <td class="simple-table-td">
                <div v-for="tag in data.tags" :key="tag">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        class="custom-status d-inline font-weight-600 custom-grey-border text-uppercase justify-center"
                        color="cyan"
                        text-color="white"
                        label
                        small
                      >
                        {{ tag }}
                      </v-chip>
                    </template>
                    <span>Tag</span>
                  </v-tooltip>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Start Date: </b>
                  <template v-if="data.start_date">{{
                    formatDate(data.start_date)
                  }}</template>
                </p>
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Expiry Date: </b>
                  <template v-if="data.expiry_date">{{
                    formatDate(data.expiry_date)
                  }}</template>
                </p>
              </td>
              <td class="simple-table-td" width="100">
                <template v-if="data.reminder_type">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reminder Type: </b>
                    <template v-if="data.reminder_type">{{
                      data.reminder_type
                    }}</template>
                  </p>
                  <p
                    v-if="data.reminder_date"
                    class="m-0 custom-nowrap-ellipsis"
                  >
                    <b>Date: </b>
                    <template v-if="data.reminder_date">{{
                      formatDate(data.reminder_date)
                    }}</template>
                  </p>
                  <p
                    v-if="data.reminder_frequency"
                    class="m-0 custom-nowrap-ellipsis"
                  >
                    <b>Frequency: </b>
                    <template v-if="data.reminder_frequency">{{
                      data.reminder_frequency
                    }}</template>
                  </p>
                  <p
                    v-if="data.reminder_start_date"
                    class="m-0 custom-nowrap-ellipsis"
                  >
                    <b>Start Date: </b>
                    <template v-if="data.reminder_start_date">{{
                      formatDate(data.reminder_start_date)
                    }}</template>
                  </p>
                  <p
                    v-if="data.reminder_end_date"
                    class="m-0 custom-nowrap-ellipsis"
                  >
                    <b>End Date: </b>
                    <template v-if="data.reminder_end_date">{{
                      formatDate(data.reminder_end_date)
                    }}</template>
                  </p>
                </template>
              </td>
              <td class="simple-table-td" width="100">
                <TableActivity
                  v-if="!lodash.isEmpty(data.added_by)"
                  :data.sync="data"
                >
                  <template v-slot:display_name>
                    {{ data.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ data.modified_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(data.updated_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="5">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no task at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <DocumentUpload
      :import-dialog="importDialog"
      :project-id="parent"
      v-on:close-dialog="importDialog = false"
      v-on:get-document-list="getAllDocumentss()"
    ></DocumentUpload>
  </v-container>
  <!--end::Ticket Internal Listing-->
</template>
<style>
.project_doc_count .flex.py-0 {
  max-width: 250px;
}
</style>
<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";

import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import DocumentUpload from "@/view/pages/partials/DocumentUpload.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, FileManagerMixin],
  name: "task-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      dataRows: [],
      dataLoading: true,
      importDialog: false,
      customerList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getAllDocumentss();
        }
      },
    },
  },
  components: {
    DocumentUpload,
    TableActivity,
    ListingTable,
    PageHeaderCount,
  },
  methods: {
    isExpired(param) {
      if (moment(param.expiry_date).isBefore(moment())) {
        return true;
      }
      return false;
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllDocumentss();
    },
    getAllDocumentss() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "documents",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
};
</script>
